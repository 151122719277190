
import { Component, Vue } from 'vue-property-decorator'
import AMapLoader from '@amap/amap-jsapi-loader'
import { mapKey } from '@/utils/config'
import { drawPolygon } from '@/utils/formatData'
import { AreaList } from '@/types/irrigation'
import { PolygonStyle } from '@/types/common'
import { ProjectDetail } from '@/types/project'
import Fullscreen from './Fullscreen.vue'
import { KeepAlive } from '@/utils/decorators'
@Component({
  components: { Fullscreen }
})
@KeepAlive
export default class IrrigationArea extends Vue {
  projectId = ''
  projectList: ProjectDetail[] = []
  areaList: AreaList[] = []
  currentIndex = 0
  currentAredId = ''
  areaName = '' // 区域名称
  avter = require('@/assets/img/grid/picture.png')
  currentDevice = {}

  private map: AMap.Map | any = null
  private polyon: AMap.Polygon | any = null
  private polygonStyle: PolygonStyle = {
    strokeColor: '#48CECE',
    fillColor: '#36CBCB',
    strokeWeight: 4,
    strokeOpacity: 1,
    fillOpacity: 0.3,
    strokeStyle: 'solid',
    zIndex: 100
  }

  private areaStyle: PolygonStyle = {
    strokeColor: '#5750EB',
    fillColor: '#5750EB',
    strokeWeight: 4,
    strokeOpacity: 1,
    fillOpacity: 0.3,
    strokeStyle: 'solid',
    zIndex: 100
  }

  created () {
    !this.map && this.loadMap().then(() => {
      this.getProject()
    })
  }

  get routeQuery () {
    return this.$route.query.projectId || ''
  }

  destroyed () {
    if (this.map) {
      if (this.map.getAllOverlays('ploygon').length > 0) {
        this.map.getAllOverlays('ploygon').map((item: AMap.Marker) => {
          item.off('click', () => {
            // 清除marker事件
          })
        })
      }
      this.map.clearMap()
      this.map.destroy()
    }
  }

  // 地图初始化
  loadMap () {
    return AMapLoader.load({
      key: mapKey,
      version: '2.0',
      plugins: []
    }).then(() => {
      const map = new AMap.Map('mapContainer', {
        zoom: 11
      })
      this.map = map
      this.map.on('mousemove', () => {
        this.closeDialog()
      })
    })
  }

  getProject () {
    this.$axios.get(this.$apis.project.selectYhProjectByList).then(res => {
      this.projectList = res || []
      if (this.projectList && this.projectList[0]) {
        if (this.routeQuery) {
          this.projectId = this.routeQuery as string
        } else {
          this.projectId = this.projectList[0].projectId
        }
        this.getProjectLocation()
        this.loadAreaList()
      }
    })
  }

  loadAreaList () {
    this.$axios.get(this.$apis.grid.selectYhProjectAreaSumList, {
      projectId: this.projectId
    }).then(res => {
      this.areaList = res || []
    })
  }

  // 查询地图围栏
  getProjectLocation () {
    this.$axios.get(this.$apis.project.selectYhProjectMapByProjectId, {
      projectId: this.projectId
    }).then(res => {
      if (res.locationList) {
        this.polyon = drawPolygon(this.map, res.locationList, this.polygonStyle, null)
        // 清除弹框
        this.polyon.forEach((item: any) => {
          item.on('mousemove', () => {
            this.closeDialog()
          })
        })
      }
      if (res.areaList && res.areaList.length > 0) {
        res.areaList.forEach((item: any, index: number) => {
          drawPolygon(this.map, item.locationList, this.areaStyle, () => {
            this.toDetail(item)
          })
          if (item.locationList && item.locationList.length > 2) {
            // const heihei = this.findCenter(item.locationList.map((data: any) => {
            //   return { lng: data.longitude, lat: data.latitude }
            // }))
            // console.log('heihei', heihei)
            const marker = new AMap.Marker({
              position: this.findCenter(item.locationList.map((data: any) => {
                return { lng: data.longitude, lat: data.latitude }
              })),
              icon: new AMap.Icon({
                size: [24, 31],
                image: require('@/assets/img/grid/dianwei_icon.svg'),
                imageSize: [24, 31]
              }),
              cursor: 'default',
              offset: new AMap.Pixel(-12, -15),
              label: {
                content: String(index + 1),
                direction: 'center'
              }
            })
            marker.on('click', () => {
              this.toDetail(item)
            })
            this.map.add(marker)
            console.log('marker', marker)
            this.$nextTick(() => {
              (marker as any).dom.children[1].style.border = 'none';
              (marker as any).dom.children[1].style.backgroundColor = 'transparent';
              (marker as any).dom.children[1].style.fontSize = '10px';
              (marker as any).dom.children[1].style.fontWeight = '600';
              (marker as any).dom.children[1].style.color = '#FFFFFF';
              (marker as any).dom.children[1].style.transform = 'translate3d(0, -5px, 0)'
            })
            // area.forEach(item1 => {
            //   item1.on('mousemove', (e: any) => {
            //     this.areaName = item.areaName
            //     // 是否请求接口
            //     if (this.currentAredId !== item1.getExtData()) {
            //       this.getWindowInfo(item.projectAreaId)
            //     }
            //     this.currentAredId = item1.getExtData()
            //     this.$nextTick(() => {
            //       const infoWindow = new AMap.InfoWindow({
            //         content: this.$refs.window as any,
            //         isCustom: true,
            //         autoMove: true,
            //         closeWhenClickMap: true,
            //         anchor: 'bottom-center',
            //         offset: new AMap.Pixel(0, -20)
            //       })
            //       infoWindow.open(this.map, [Number(e.lnglat.lng), Number(e.lnglat.lat)])
            //     })
            //   })
            //   item1.on('click', (e: any) => {
            //     this.$router.push({
            //       path: '/grid/detail',
            //       query: { areaId: item.projectAreaId }
            //     })
            //   })
            // })
          }
        })
      }
      this.map.setFitView(this.polyon)
    })
  }

  toDetail (item: any) {
    this.$router.push({
      path: '/grid/detail',
      query: {
        areaId: item.projectAreaId,
        projectId: this.projectId
      }
    })
  }

  findCenter (points: any) {
    console.log('points', points)
    const pointNum = points.length // 坐标点个数
    let X = 0
    let Y = 0
    let Z = 0
    for (let i = 0; i < points.length; i++) {
      if (!points[i]) {
        continue
      }
      const point = points[i]
      const lat = parseFloat(point.lat) * Math.PI / 180
      const lng = parseFloat(point.lng) * Math.PI / 180
      const x = Math.cos(lat) * Math.cos(lng)
      const y = Math.cos(lat) * Math.sin(lng)
      const z = Math.sin(lat)
      X += x
      Y += y
      Z += z
    }
    X = X / pointNum
    Y = Y / pointNum
    Z = Z / pointNum

    const tmpLng = Math.atan2(Y, X)
    const tmpLat = Math.atan2(Z, Math.sqrt(X * X + Y * Y))
    // return [tmpLng * 180 / Math.PI, tmpLat * 180 / Math.PI]
    return new AMap.LngLat(tmpLng * 180 / Math.PI, tmpLat * 180 / Math.PI)
  }

  closeDialog () {
    this.map && this.map.clearInfoWindow()
    this.currentAredId = ''
    this.currentDevice = {}
  }

  // 获取区域
  getWindowInfo (projectAreaId: string) {
    this.$axios.get(this.$apis.grid.selectGridProjectAreaDetail, {
      projectAreaId: projectAreaId
    }).then(res => {
      this.currentDevice = res
    })
  }

  changeProject () {
    this.map && this.map.clearMap()
    this.getProjectLocation()
    this.loadAreaList()
  }

  changeLayer (value: number) {
    this.currentIndex = value
    this.map.setLayers([])
    if (value === 0) {
      // 添加标准地图图层
      this.map.setMapStyle('amap://styles/normal')
      this.map.add(new AMap.TileLayer())
    } else {
      // 添加卫星地图图层
      this.map.add(new AMap.TileLayer.Satellite())
    }
  }
}
